import store from "@/store";
import permissions from "@/helpers/permissions";

export default {
    ...permissions,
    /**
     * Handles error page based on failed permissions check
     *
     * @param {String} permission
     * @param {Object} restriction
     * @param {Object} query
     * @returns {{name: string, params: {error: string}}}
     */
    getRouteByPermissionFail(permission, restriction, query = {}) {

        const userPermissions = permission
            ? [permission].concat(store.getters["user/permissions/permissions"])
            : store.getters["user/permissions/permissions"];

        // Check restrictions through each user allowed permission
        for (let i = 0; i < userPermissions.length; i++) {

            if (restriction || store.getters["user/permissions/hasRestriction"](userPermissions[i])) {

                restriction = restriction ? restriction : store.getters["user/permissions/getRestriction"](userPermissions[i]);

                if ("undefined" !== typeof restriction.isUserDisabled && true === restriction.isUserDisabled) {

                    return {name: "errors.403", params: {error: "user-disabled"}, query};
                }

                if ("undefined" !== typeof restriction.isLoginFailure && true === restriction.isLoginFailure) {

                    return {name: "errors.403", params: {error: "non-employees"}, query};
                }

                if ("undefined" !== typeof restriction.isUserInSupportedCountry && false === restriction.isUserInSupportedCountry) {

                    return {name: "errors.403", params: {error: "eligibility-location"}, query};
                }

                if ("undefined" !== typeof restriction.isUserTypeAccessAllowed && false === restriction.isUserTypeAccessAllowed) {

                    return {name: "errors.403", params: {error: "not-allowed"}, query};
                }

                if ("undefined" !== typeof restriction.isUserAllowed && false === restriction.isUserAllowed) {

                    return {name: "errors.403", params: {error: "eligibility"}, query};
                }
            }
        }

        return permissions.getRouteByPermissionFail(permission, restriction);
    }
}