export default {
    namespaced: true,
    state: {
        permissions: null,
        restrictions: null
    },
    getters: {
        permissions: state => state.permissions,
        restrictions: state => state.restrictions,
        hasPermission: state => permission => {

            if (null === state.permissions) {

                return false;
            }

            if ("string" === typeof permission) {

                return -1 !== state.permissions.indexOf(permission);
            }

            return 0 < state.permissions.filter(userPermission => permission.includes(userPermission)).length;
        },
        hasRestriction: state => permission => null !== state.restrictions && "undefined" !== typeof state.restrictions[permission],
        getRestriction: state => permission => null === state.restrictions || "undefined" === typeof state.restrictions[permission]
            ? null
            : state.restrictions[permission]
    },
    mutations: {
        setPermissions: (state, permissions) => state.permissions = permissions,
        setRestrictions: (state, restrictions) => state.restrictions = restrictions,
    },
    actions: {
        setPermissions: (context, permissions) => context.commit("setPermissions", permissions),
        setRestrictions: (context, restrictions) => context.commit("setRestrictions", restrictions),
        clear: (context) => context.commit("setPermissions", null) && context.commit("setRestrictions", null)
    }
}