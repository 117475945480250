<template>
    <div class="categories-list-category">
        <router-link class="thumbnail" :to="route">
            <img class="image" :src="image" :alt="name"/>
        </router-link>

        <router-link class="title" :to="route">
            {{name}}
        </router-link>

        <div v-if="true === displayCta" class="cta">
            <router-link class="btn btn-sm btn-primary d-inline-flex" :to="route">
                <span>{{t('cta')}}</span>
                <span class="icon icon-right-open"></span>
            </router-link>
        </div>
    </div>
</template>

<script>

    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    import Panel from "@/components/Panel";

    export default {
        name: "CategoriesListCategory",
        components: {
            Panel
        },
        props: {
            category: {
                type: Object,
                required: true
            },
            grouping: {
                type: String,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.categories.list.category",
            };
        },
        computed: {
            displayCta() {

                return 1 === parseInt(process.env.VUE_APP_COMPONENT_CATEGORIES_LIST_CATEGORY_DISPLAY_VIEW_BUTTON)
            },
            name() {

                return getTranslationValue(this.category, ["name"], this.$i18n);
            },
            image() {

                return this.category.image;
            },
            route() {

                return {name: "products", params: {grouping: this.grouping, grouping_id: this.category.id}};
            }
        }
    }
</script>