export default {
    disclaimer: {
        label_default: "Reconheço e concordo que os itens que estou encomendando/recebendo são DXC propriedade a ser usada para DXC atividades relacionadas ao trabalho e, como tal, DXC reserva-se o direito de solicitar a devolução do imóvel após minha saída de DXC. Para obter mais detalhes, consulte <a href='https://dxcportal.sharepoint.com/:b:/r/sites/policy/Shared%20Documents/Information%20Technology/DXC%20IT%20Asset%20Management%20Policy.pdf?csf=1&web=1&e=sW0yCH' target='_blank' rel='noopener'>DXC Política de gerenciamento de ativos de TI</a> .",
        label_type_1: "Confirmo que a autoavaliação para o trabalho de casa foi concluída com precisão e de boa fé, e que qualquer equipamento ou mobiliário foi fornecido por um DXC local ou solicitado através de DXC O equipamento será usado por mim para o propósito do meu trabalho com DXC. Reconheço e concordo que os itens que estou encomendando/recebendo são DXC propriedade a ser usada para DXC atividades relacionadas ao trabalho e, como tal, DXC reserva-se o direito de solicitar a devolução do imóvel após minha saída de DXC. Para obter mais detalhes, consulte <a href='https://dxcportal.sharepoint.com/:b:/r/sites/policy/Shared%20Documents/Information%20Technology/DXC%20IT%20Asset%20Management%20Policy.pdf?csf=1&web=1&e=sW0yCH' target='_blank' rel='noopener'>DXC Política de gerenciamento de ativos de TI</a> .",
        errors: {
            required: "Por favor, aceite os termos de uso"
        }
    },
    preferred_delivery_date: {
        "Preferred Delivery Date": "Data de entrega preferida"
    },
    order_note: {
        label: "Nota de pedido",
        placeholder: "Nota para o aprovador neste pedido",
    },
    send_emails_to_oobo_orderer: {
        "OOBO": "Notificar-me com atualizações por esta ordem"
    },
    info: {
        label: "Nota: Para garantir a entrega bem-sucedida dos seus artigos, introduza os dados completos do seu endereço, incluindo quaisquer instruções especiais. Verifique novamente se o PIN e o número de telefone estão completos e corretos antes de enviar o seu pedido. Uma vez enviados, os detalhes não podem ser alterados e as encomendas não podem ser canceladas."
    }
}