<template>
    <div class="p-3">
        <loading/>
    </div>
</template>

<script>

    import mixin_auth from "@mixins_auth";
    import {AUTOLOGIN_SESSION_ID} from "@/helpers/auth";

    export default {
        name: "ViewsAuthLoginSso",
        mixins: [mixin_auth],
        data() {

            return {
                data: null,
                sessionId: null
            };
        },
        methods: {
            init() {

                try {

                    this.data = JSON.parse(this.$route.query.data);
                    this.sessionId = this.$storage.get(AUTOLOGIN_SESSION_ID);
                } catch (e) {

                    this.$router.push({name: "errors.401"})
                }
            },
            fetch() {

                this.init();

                if (this.auth_check(this.data)) {

                    this.$platform
                        .post("/api/v2/login/session", {session: this.sessionId})
                        .then(response => {

                            this.$storage.remove(AUTOLOGIN_SESSION_ID);

                            const data = {
                                ...this.data,
                                token: response.data.results.token,
                                permissions: this.auth_permissions(this.data)
                            };

                            this.auth_prepare(data);

                            this.auth_authenticate(
                                data.token,
                                data.userId,
                                data.stylistTags ? data.stylistTags.map(t => t.name) : [],
                                data.permissions,
                                data.requireAcknowledgement,
                                data.expirationTime,
                            );
                        })
                        .catch(() => this.$router.push({name: "errors.401"}))
                } else {

                    this.$router.push({name: "errors.401"})
                }
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>