export default {
    title: {
        new: "Adicionar novo endereço",
        complete: "Endereço Completo",
        edit: "Editar Endereço"
    },
    incomplete: "Por favor, preencha os campos de endereço obrigatórios",
    info: {
        IND: "Nota: Para garantir a entrega bem-sucedida dos seus artigos, introduza os dados completos do seu endereço, incluindo quaisquer instruções especiais. Verifique novamente se o PIN e o número de telefone estão completos e corretos antes de enviar o seu pedido. Uma vez enviados, os detalhes não podem ser alterados e as encomendas não podem ser canceladas."
    },
    model: {
        country: {
            label: "País"
        }
    },
    field: {
        default: {
            name: {
                label: {Name: "Nome"},
                placeholder: {Name: "Nome"}
            },
            personalEmail: {
                label: {"Personal Email": "E-mail pessoal"},
                placeholder: {"Personal Email": "E-mail pessoal"}
            },
            managerEmail: {
                label: {"Manager Email": "E-mail do gerente"},
                placeholder: {"Manager Email": "E-mail do gerente"}
            },
            managerName: {
                label: {"Manager Name": "Nome do gerente"},
                placeholder: {"Manager Name": "Nome do gerente"}
            },
            postalCode: {
                label: {Zipcode: "CEP"},
                placeholder: {Zipcode: "CEP"}
            },
            phoneNumber: {
                label: {"Phone Number": "Número de telefone"},
                placeholder: {"Phone Number": "Número de telefone"}
            },
            firstStreet: {
                label: {"Street": "Rua", "Street 1": "Rua 1"},
                placeholder: {"Street": "Rua", "Street 1": "Rua 1"}
            },
            secondStreet: {
                label: {"Street": "Rua", "Street 2": "Rua 2"},
                placeholder: {"Street": "Rua", "Street 2": "Rua 2"}
            },
            region: {
                label: {"Locality": "Localidade"},
                placeholder: {"Locality": "Localidade"}
            },
            city: {
                label: {
                    "City": "Cidade",
                    "Town/City": "Cidade"
                },
                placeholder: {
                    "City": "Cidade",
                    "Town/City": "Cidade"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identificador",
                    "Identifier": "Identificador"
                },
                placeholder: {
                    "identifier": "Identificador",
                    "Identifier": "Identificador"
                }
            },
            state: {
                label: {"State": "Estado"},
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alasca",
                    "Arizona": "Arizona",
                    "Arkansas": "Arcansas",
                    "California": "Califórnia",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "Distrito de Columbia",
                    "Florida": "Flórida",
                    "Georgia": "Geórgia",
                    "Hawaii": "Havaí",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Luisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississipi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebrasca",
                    "Nevada": "Nevada",
                    "New Hampshire": "Nova Hampshire",
                    "New Jersey": "Nova Jersey",
                    "New Mexico": "Novo México",
                    "New York": "Nova Iorque",
                    "North Carolina": "Carolina do Norte",
                    "North Dakota": "Dakota do Norte",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pensilvânia",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "Carolina do Sul",
                    "South Dakota": "Dakota do Sul",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virgínia",
                    "Washington": "Washington",
                    "West Virginia": "Virgínia Ocidental",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            deliveryInstructions: {
                label: {
                    "Delivery Instructions": "Instruções de entrega"
                },
                placeholder: {
                    "Extra address information, delivery instructions, etc": "Informações extras de endereço, instruções de entrega, etc."
                }
            },
            servicePoint: {
                label: {"Service Point": "Ponto de serviço"},
                placeholder: {"Service Point": "Ponto de serviço"},
                options: {
                    "Front Door": "Porta da frente",
                    "Back Door": "Porta dos fundos"
                }
            },
            parkingRestrictions: {
                label: {"Restrictions for Parking": "Restrições de estacionamento"},
                placeholder: {"Restrictions for Parking": "Restrições de estacionamento"},
                options: {
                    "Yes": "Sim",
                    "No": "Não"
                }
            },
            lockOrGateCodeRequired: {
                label: {"Lock or Gate Code Required": "Código de bloqueio ou portão necessário"},
                placeholder: {"Lock or Gate Code Required": "Código de bloqueio ou portão necessário"},
                options: {
                    "Yes": "Sim",
                    "No": "Não"
                }
            },
            animals: {
                label: {"Animals at Property": "Animais na propriedade"},
                placeholder: {"Animals at Property": "Animais na propriedade"},
                options: {
                    "Yes": "Sim",
                    "No": "Não"
                }
            },
            stairs: {
                label: {"Use Stairs": "Use escadas"},
                placeholder: {"Use Stairs": "Use escadas"},
                options: {
                    "Yes": "Sim",
                    "No": "Não"
                }
            }
        },
        ARG: {
            identifier: {
                placeholder: {"Tax ID (CDI, CUIT, CUIL, etc)": "cdi, cuit, cuil, dni"}
            },
            firstStreet: {
                placeholder: {"Number/Street/Apartment": "rua, endereço, apartamento, andar, bairro"}
            },
            city: {
                placeholder: {"Town/City/Locality": "vila, cidade, localidade"}
            },
            postalCode: {
                placeholder: {"CPA": "código postal argentino"}
            },
            deliveryInstructions: {
                placeholder: {"Extra address information, delivery instructions, etc": "endereço adicional extra ou endereço exato, etc."}
            }
        },
        BRA: {
            name: {
                label: {"Full name": "Nome completo"},
                placeholder: {"Full name": "Nome completo"}
            },
            firstStreet: {
                label: {"Address 1": "Endereço 1"},
                placeholder: {"Address 1": "Rua + número da casa/apartamento"}
            },
            secondStreet: {
                label: {"Address 2": "Endereço 2"},
                placeholder: {"Address 2": "Rua + número da casa/apartamento"}
            },
            city: {
                label: {City: "Cidade"},
                placeholder: {City: "Cidade"},
            },
            state: {
                label: {State: "Estado"},
                placeholder: {State: "Estado"}
            },
            postalCode: {
                label: {"Zip code": "CEP"},
                placeholder: {"Zip code": "CEP"}
            },
            identifier: {
                label: {CPF: "CPF"},
                placeholder: {CPF: "CPF"}
            }
        },
        IND: {
            state: {
                label: {"State/Territory": "Estado/Território"},
                options: {
                    "Andaman and Nicobar Islands": "Ilhas Andaman e Nicobar",
                    "Arunachal Pradesh": "Arunachal Pradesh",
                    "Assam": "Assam",
                    "Bihar": "Bihar",
                    "Chandigarh": "Chandigarh",
                    "Chhattisgarh": "Chattisgarh-Cidade",
                    "Dadra and Nagar Haveli and Daman and Diu": "Dadra e Nagar Haveli e Damão e Diu",
                    "Delhi": "Délhi",
                    "Goa": "Goa",
                    "Gujarat": "Gujarat",
                    "Haryana": "Haryana",
                    "Himachal Pradesh": "Himachal Pradesh",
                    "Jammu and Kashmir": "Jammu e Caxemira",
                    "Jharkhand": "Jharkhand",
                    "Karnataka": "Karnataka",
                    "Kerala": "Querala",
                    "Ladakh":"Ladakh",
                    "Lakshadweep": "Lakshadweep",
                    "Madhya Pradesh": "Madhya Pradesh",
                    "Maharashtra": "Maharashtra",
                    "Manipur": "Manipur",
                    "Meghalaya": "Meghalaya",
                    "Mizoram": "Mizoram",
                    "Nagaland": "Nagalândia",
                    "Odisha": "Orissa",
                    "Puducherry": "Puducherry",
                    "Punjab": "Punjab",
                    "Rajasthan": "Rajastão",
                    "Sikkim": "Siquim",
                    "Tamil Nadu": "Tâmil Nadu",
                    "Telangana":"Telangana",
                    "Tripura": "Tripura",
                    "Uttar Pradesh": "Utar Pradesh",
                    "Uttarakhand": "Uttarakhand",
                    "West Bengal": "Bengala Ocidental",
                    "Andhra Pradesh": "Andhra Pradesh"
                }
            }
        }
    },
    errors: {
        required: "{field} é obrigatório.",
        mask: "{field} é inválido."
    },
    buttons: {
        submit: {
            text: "Salvar",
            saving: "Salvando"
        },
        cancel: {
            text: "Cancelar"
        }
    },
    disclaimer: "Adicionar este endereço exigirá verificação de e-mail. Após salvar o endereço, verifique seu e-mail e clique no link dentro dele.",
    message: {
        added: "Endereço foi adicionado",
        updated: "O endereço foi atualizado"
    }
}