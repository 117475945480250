<template>
    <page-message v-if="false === isRelogin">
        <template #header>{{t('header')}}</template>
        <template #cta>
            <button v-if="true === processing" type="button" class="btn btn-primary" disabled>
                <loading/>
            </button>
            <router-link v-else :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.login')}}</router-link>
        </template>
    </page-message>
    <div v-else class="container">
        <div class="row">
            <div class="col-12 pt-3">
                <loading/>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin_auth from "@mixins_auth";
    import {USER_TOKEN_STORAGE_KEY, USER_IMPERSONATOR_TOKEN_STORAGE_KEY} from "@/api/storage";

    import PageMessage from "@/components/page/PageMessage";
    import Loading from "@/components/Loading";

    export default {
        name: "ViewsAuthLogout",
        mixins: [mixin_auth],
        components: {
            Loading,
            PageMessage
        },
        data() {

            return {
                t_path: "views.auth.logout",
                processing: false
            };
        },
        computed: {
            isRelogin() {

                return "true" === this.$route.query.relogin;
            },
        },
        methods: {
            logout() {

                // Remove token from selected storage
                this.$storage.remove(USER_TOKEN_STORAGE_KEY);

                // Logout user
                this.$store.dispatch("user/logout");
            },
            switchAccount() {

                if ("true" === this.$route.query.switch) {

                    const impersonator = this.$storage.get(USER_IMPERSONATOR_TOKEN_STORAGE_KEY);

                    if (null !== impersonator) {

                        this.processing = true;

                        this.auth_authenticate(
                            impersonator.token,
                            impersonator.id,
                            impersonator.stylistTags,
                            impersonator.permissions,
                            impersonator.requireAcknowledgement,
                            this.$storage.getExpire(USER_IMPERSONATOR_TOKEN_STORAGE_KEY) * 1000
                        );
                    }
                }

                this.$storage.remove(USER_IMPERSONATOR_TOKEN_STORAGE_KEY);
                this.$store.commit("user/setImpersonatorToken", null);
            },
            relogin() {

                if (true === this.isRelogin) {

                    this.$router.push({name: "home"});
                }
            }
        },
        mounted() {

            this.logout();
            this.switchAccount();
            this.relogin();
        }
    }
</script>