<template>

    <form @submit.prevent="base_submit('model', 'submit', null)">

        <div v-if="loading" class="container">
            <div class="row">
                <div class="col-12">
                    <loading/>
                </div>
            </div>
        </div>
        <div v-else class="container">
            <quiz-page v-if="page"
                       v-model="model"
                       :quiz="quiz"
                       :quiz-page="page"
                       :components="components"
                       :disabled="processing"
                       :is-last-page="isLastPage"
                       :display-back-button="displayBackButton"
                       :next-button-text="nextButtonText"
                       :back-button-text="backButtonText"
                       @back="back"
                       @next="next"
                       @title="view_setTitle"/>
        </div>
    </form>

</template>

<script>

    import mixin_view from "@/mixins/view";
    import mixin_quiz_page from "@/mixins/quiz/page.mjs";
    import mixin_quiz_components from "@/mixins/quiz/components.mjs";
    import mixin_quiz_profile from "@/mixins/quiz/profile.mjs";
    import mixin_quiz_css from "@/mixins/quiz/css.mjs";
    import mixin_quiz_payload from "@/mixins/quiz/payload.mjs";
    import quiz from "@quiz_equipment";
    import {USER_TOKEN_STORAGE_KEY} from "@/api/storage";
    import {INITIAL_EQUIPMENT_VERSION} from "@/store/user/profile";
    import {AUTOLOGIN_ENABLED, PERMISSIONS} from "@/constants";

    import QuizPage from "@/components/quiz/QuizPage";
    import Loading from "@/components/Loading";

    export default {
        name: "ViewsQuiz",
        mixins: [mixin_view, mixin_quiz_page, mixin_quiz_components, mixin_quiz_profile, mixin_quiz_css, mixin_quiz_payload],
        components: {
            Loading,
            QuizPage,
        },
        data() {

            return {
                t_path: "views.quiz",
                processing: false,
                page: null,
                model: {},
                quiz,
                queue: [],
                loading: true,
                submitted: !this.$route.query.reset && true === this.$store.getters["user/profile/equipment_submitted"]
            }
        },
        computed: {
            isLastPage() {

                return this.quiz_page_isLastPage(
                    quiz,
                    this.page,
                    true === this.submitted,
                    this.$store.getters["user/profile/equipment_submitted_version"],
                    this.model.allowedEquipmentVersion
                );
            },
            displayBackButton() {

                const pages = this.quiz_page_getQueue(
                    quiz,
                    true === this.submitted,
                    this.$store.getters["user/profile/equipment_submitted_version"],
                    this.model.allowedEquipmentVersion
                );

                return this.page && 0 < pages.findIndex(page => page.id == this.page.id);
            },
            nextButtonText() {

                return this.isLastPage ? this.t("buttons.submit") : this.t("buttons.next");
            },
            backButtonText() {

                return this.t("buttons.back");
            },
            components() {

                if (!this.page || !this.page.components) {
                    // Return empty list if page does not have components in config
                    return [];
                }

                // Get only visible components
                const components = this.quiz_getComponentsToDisplay(quiz, this.page.components);

                // Clean current page components in case it's hidden
                this.page.components.forEach(pageComponent => {

                    const found = components.reduce(
                        (result, component) => result ? result : pageComponent.name == component.name,
                        false
                    );

                    if (!found) {

                        // TODO: make it configurable
                        // For IND we should not display entity when OKTA_entity is presented
                        if ("entity" != pageComponent.name || !this.model.OKTA_entity) {

                            delete this.model[pageComponent.name];
                        }

                    }
                });

                return components.map(component => this.quiz_components_process(quiz, component.name));
            },
            content() {

                return this.quiz_page_getPageContent(quiz, this.page);
            }
        },
        methods: {
            next() {

                if (!this.page.id) {

                    return this.saveCountry();
                }

                this.submit();
            },
            back() {

                this.page = this.quiz_page_getPrevPage(
                    quiz,
                    this.page,
                    true === this.submitted,
                    this.$store.getters["user/profile/equipment_submitted_version"],
                    this.model.allowedEquipmentVersion
                );

                this.refresh();

            },
            submit() {

                this.$store.dispatch("user/quiz/equipment/setModel", {
                    ...this.model
                });

                if (this.isLastPage) {

                    return this.saveProfile();
                }

                this.page = this.quiz_page_getNextPage(
                    quiz,
                    this.page,
                    true === this.submitted,
                    this.$store.getters["user/profile/equipment_submitted_version"],
                    this.model.allowedEquipmentVersion
                );
            },
            initPage() {

                if (this.page && this.page.init && "function" === typeof this[this.page.init]) {

                    this[this.page.init](this);
                }
            },
            saveCountry() {

                this.processing = true;
                // We have to save country
                return this.$store
                    .dispatch("user/profile/post", this.quiz_payload_get(quiz, this.model, ["country"]))
                    .then(() => {

                        this.$store.dispatch("user/cart/clear");

                        if (false === this.$store.getters["user/permissions/hasPermission"](PERMISSIONS.EMPLOYEE)) {

                            // force user to re-login to renew list of permissions
                            return this.$router.push({name: "auth.logout", query: {relogin: "true"}});
                        } else {

                            this
                                .$platform
                                .post(
                                    "/api/v2/widget/available-constraints",
                                    this.quiz_payload_get(quiz, this.model, ["country"])
                                )
                                .then(response => {

                                    this.model.constraints = Object
                                        .keys(response.data.results.constraints.type)
                                        .filter(key => 0 < response.data.results.constraints.type[key]);

                                    this.submit();
                                });
                        }
                    })
                    .catch(error => {

                        // TODO: use config file
                        if (error.response && 403 == error.response.status) {

                            let targetError = null;

                            switch (this.model.country) {
                                case "IND":
                                    targetError = "eligibility-location";
                                    break;

                                default:
                                    targetError = "country";
                            }

                            // Logout user: Remove token from selected storage
                            this.$storage.remove(USER_TOKEN_STORAGE_KEY);

                            return this.$store.dispatch("user/logout")
                                .then(() => this.$router.push({name: "errors.403", params: {error: targetError}}))
                                .catch(() => this.$router.push({name: "errors.403", params: {error: targetError}}));
                        }

                        this.base_error(error);
                        this.processing = false;
                    });
            },
            saveProfile() {

                this.processing = true;

                // Filter allowed components
                const components = this
                    .quiz_page_getQueue(
                        quiz,
                        true === this.submitted,
                        this.$store.getters["user/profile/equipment_submitted_version"],
                        this.model.allowedEquipmentVersion
                    )
                    .filter(page => this.quiz_isPageShouldBeDisplayed(quiz, page))
                    .reduce((result, page) => "undefined" === typeof page.components ? result : result.concat(page.components), [])
                    .map(component => component.name);

                const request = {
                    ...this.quiz_payload_get(quiz, this.model, components, null),
                    equipment_submitted: true
                };

                this.$store.dispatch("user/profile/post", request)
                    .then(() => {

                        this.$store.dispatch("user/cart/clear");
                        return this.$store.dispatch("user/fetch")
                    })
                    .then(() => this.$router.push({name: "home"}))
                    .catch(error => {
                        this.base_error(error);
                        this.processing = false;
                    });
            },
            saveEmptyProfile() {

                this.$store.dispatch("user/profile/post", {equipment_submitted: true})
                    .then(() => {

                        this.$store.dispatch("user/cart/clear");
                        return this.$store.dispatch("user/fetch")
                    })
                    .then(() => this.$router.push({name: "home"}))
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false);
            },
            refresh() {

                if (this.page) {

                    this.$router.replace({
                        name: "quiz",
                        params: this.page.id ? {page: this.page.id} : {},
                        query: this.$route.query
                    });
                }
            },
            initEquipmentList() {

                // Add spinner to page
                this.page.description = "description.loading";
                this.page.text = "";

                this.$platform.post("/api/v2/widget/available-constraints", this.quiz_payload_get(quiz, this.model))
                    .then(response => {

                        const items = Object
                            .keys(response.data.results.constraints.type)
                            .filter(type => 0 < response.data.results.constraints.type[type])
                            .map(type => this.$t(`products.types.${type}`));

                        this.page.description = 0 === items.length
                            ? "description.empty"
                            : "description.allowed";

                        if (0 < items.length) {

                            this.page.text = this.t(`${this.page.id}.text`, {
                                list: items.map(item => `<li>${item}</li>`).join("")
                            });
                        }
                    })
                    .catch(error => {

                        this.page.description = "error";
                        this.base_error(error);
                    })
            }
        },
        mounted() {

            this.loading = true;

            Promise.all([this.$store.dispatch("user/quiz/fetch"), this.$store.dispatch("user/quiz/equipment/fetch")])
                .then(() => {

                    this.model = Object.assign(this.$store.getters["user/profile/profile"], this.$store.getters["user/quiz/equipment/model"]);

                    if (true === AUTOLOGIN_ENABLED) {

                        this.model.SETTINGS_autologin = true;
                    }

                    if (this.$store.getters["user/profile/country"]) {

                        // Pull country from user profile
                        this.model.country = this.$store.getters["user/profile/country"];
                    }

                    // Figure out allowed equipment version based on config and selected country
                    this.model.allowedEquipmentVersion = this.quiz_profile_getAllowedVersion(
                        this.model.country,
                        this.$store.getters["user/profile/equipmentVersions"],
                        INITIAL_EQUIPMENT_VERSION
                    );


                    this.queue = this.quiz_page_getQueue(
                        quiz,
                        true === this.submitted,
                        this.$store.getters["user/profile/equipment_submitted_version"],
                        this.model.allowedEquipmentVersion
                    );

                    if (0 === this.queue.length) {

                        return this.saveEmptyProfile();
                    }

                    // Find page
                    this.page = this.queue.find(page => this.$route.params.page == page.id || (!this.$route.params.page && !page.id));

                    if (!this.page) {

                        this.page = this.queue[0];
                    }

                    // Check for this page should be displayed
                    if (!this.quiz_isPageShouldBeDisplayed(quiz, this.page)) {

                        this.page = this.quiz_page_getNextPage(
                            quiz,
                            this.page,
                            true === this.submitted,
                            this.$store.getters["user/profile/equipment_submitted_version"],
                            this.model.allowedEquipmentVersion
                        );

                        if (null === this.page) {

                            if (this.model.allowedEquipmentVersion != this.$store.getters["user/profile/equipment_submitted_version"]) {

                                // User is allowed to have higher equipment version, but does not have access to newer equipment version pages
                                this.saveProfile();

                                return;
                            }

                            throw new Error("Current page is not allowed, but there is no next page");
                        }
                    }

                    this.initPage();

                    this.quiz_components_processOKTA();

                    this.loading = false;
                });
        },
        watch: {
            model: {
                deep: true,
                handler() {

                    this.model.allowedEquipmentVersion = this.quiz_profile_getAllowedVersion(
                        this.model.country,
                        this.$store.getters["user/profile/equipmentVersions"],
                        INITIAL_EQUIPMENT_VERSION
                    );
                }
            },
            page: {
                immediate: true,
                deep: true,
                handler() {

                    this.refresh();
                }
            },
            components: {
                immediate: true,
                deep: true,
                handler(components) {

                    components.forEach(component => {

                        const pageComponent = this.page.components.find(pageComponent => pageComponent.name == component.name);

                        if (pageComponent && pageComponent.value && !this.model[component.name]) {

                            this.model[component.name] = pageComponent.value;
                        }
                    });
                }
            }
        }
    }
</script>