import mixin_catalog from "@/mixins/catalog";
import {getTranslationValue} from "@/i18n/utils/lang_overrides";
import {sanitize} from "@/helpers/html";
import {ORDER_STATUS, PRODUCT_TAG_GENERIC} from "@/constants";

export default {
    mixins: [mixin_catalog],
    computed: {
        products_message() {

            return this.$te("catalog.infoMessage") ? this.$t("catalog.infoMessage") : null
        }
    },
    methods: {
        products_translateProductName(product) {

            return getTranslationValue(product, ["name", "genericName", "generic_name"], this.$i18n);
        },
        products_translateProductDescription(product, allowedHtmlTags) {

            return sanitize(
                getTranslationValue(product, [
                    "descriptionText",
                    "description_text",
                    "description",
                    "genericDescription",
                    "generic_description"
                ], this.$i18n),
                allowedHtmlTags
            );
        },
        products_translateProductNote(product) {

            return sanitize(getTranslationValue(product, "note", this.$i18n));
        },
        products_translateProductOrderingNote(product) {

            return sanitize(getTranslationValue(product, "ordering_note", this.$i18n));
        },
        products_translateProductEstimatedShipDate(product) {

            if (product.estimated_ship_date) {

                return product.estimated_ship_date
                    ? sanitize(getTranslationValue(product.estimated_ship_date, ["message"], this.$i18n))
                    : null;
            }

            if (product.estimatedShipDate) {

                return product.estimatedShipDate
                    ? sanitize(getTranslationValue(product.estimatedShipDate, ["message"], this.$i18n))
                    : null;
            }

            return null;
        },
        products_getBreadcrumbsTree(includeActive) {

            const breadcrumbs = this.$route.meta && this.$route.meta.breadcrumbs
                ? this.$route.meta.breadcrumbs.slice(0)
                : [];

            const active = this.$store.getters["grouping/active"];

            // Prepare products for mobile
            breadcrumbs.push({
                text: this.$t("breadcrumbs.catalog"),
                class: "d-md-none",
                dropdown: this.catalog_getCategories().map(item => ({
                    text: getTranslationValue(item, "name", this.$i18n),
                    active: active && active.id === item.id,
                    to: {
                        name: "products.proxy",
                        params: {
                            grouping: this.$route.params.grouping,
                            grouping_id: item.id
                        }
                    }
                }))
            });

            // Prepare breadcrumbs for desktop
            this.$store.getters["grouping/chain"].forEach(parent => breadcrumbs.push({
                text: getTranslationValue(parent, "name", this.$i18n),
                to: {
                    name: "products",
                    params: {
                        grouping_id: parent.id
                    }
                }
            }));

            if (includeActive && active) {

                breadcrumbs.push({
                    text: getTranslationValue(active, "name", this.$i18n),
                    to: {
                        name: "products",
                        params: {
                            grouping_id: active.id
                        }
                    }
                });
            }

            return breadcrumbs;
        },
        products_updateBreadcrumbsTree(active) {

            const breadcrumbs = this.products_getBreadcrumbsTree();

            if (active) {

                breadcrumbs.push({
                    text: getTranslationValue(active, "name", this.$i18n)
                });
            }

            setTimeout(() => this.breadcrumbs_set(breadcrumbs), 100);
        },
        products_updateBreadcrumbsProduct(product) {

            const breadcrumbs = this.products_getBreadcrumbsTree(true);

            if (product) {

                breadcrumbs.push({
                    text: getTranslationValue(product, "name", this.$i18n)
                });
            }

            setTimeout(() => this.breadcrumbs_set(breadcrumbs), 100);
        },
        products_translateProductType(product) {

            return product.product_type && product.product_type[0] && this.$te(`products.types.${product.product_type[0]}`)
                ? this.$t(`products.types.${product.product_type[0]}`)
                : "";
        },
        products_productOrdered(product) {

            const orderProducts = this.$store
                .getters["user/orders/products"]
                .filter(
                    orderProduct => -1 === [
                        ORDER_STATUS.CANCELED,
                        ORDER_STATUS.RETURNED,
                        ORDER_STATUS.NOT_APPROVED,
                        ORDER_STATUS.TRANSFERRED
                    ].indexOf(orderProduct.status)
                );

            return -1 !== orderProducts.findIndex(orderProduct => orderProduct.sku === product.sku)
                || (
                    PRODUCT_TAG_GENERIC === product.tag
                    && -1 !== orderProducts.findIndex(orderProduct => orderProduct.related_sku === product.sku)
                );
        }
    }
}